sup {
  top: -0.75em;
}

blockquote,
.blockquote {
  line-height: 1.3;
  font-size: 2.25rem;
  font-style: italic;
}

.bg-primary {
  &.text-light,
  .text-light {
    color: #dee7f1 !important;
  }

  .text-muted {
    color: $white !important;
    opacity: .5;
  }
}

.blockquote-footer {
  font-size: 1em;

  cite {
    font-style: normal;
  }
}

.lead {
  font-size: 1.35rem;
  font-style: normal;
}

@mixin clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

@include media-breakpoint-up(xl) {
  .display-1 {
    font-size: 5rem;
    line-height: 1;
  }

  .display-2 {
    font-size: 4rem;
    line-height: 1;
  }

  .display-5 {
    font-size: 3rem;
    line-height: 1.2;
  }

  .display-6 {
    font-size: 2rem;
    line-height: 1.2;
  }
}

@include media-breakpoint-up(xxl) {
  .lead {
    font-size: 1.5rem;
    line-height: 1.6;
    font-style: normal;
  }

  .display-1 {
    font-size: 6rem;
    line-height: 1;
  }

  .display-2 {
    font-size: 5rem;
    line-height: 1;
  }

  .display-5 {
    font-size: 4rem;
    line-height: 1.2;
  }

  .display-6 {
    font-size: 3rem;
    line-height: 1.2;
  }
}
