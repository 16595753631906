//
// theme.scss
// Use this to customize your (bootstrap) theme
//

/*! purgecss start ignore */
@import 'fonts';
@import 'animations';
//@import 'accordion';
//@import 'forms';
@import 'helper';
@import 'text';
@import 'swiper';
//@import 'card';
//@import 'cover';
@import 'navbar';
@import 'navbar-height';
@import 'buttons';
//@import 'hamburger';
//@import 'alert';
//@import 'lists';
//@import 'offcanvas';
//@import 'dropdown';

@import 'template';
@import 'sidebar';
@import 'header';
@import 'footer';

@import 'custom';
/*! purgecss end ignore */
