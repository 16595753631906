.t-1 {
  top: 1px;
  position: relative;
}

.t-2 {
  top: 2px;
  position: relative;
}

.t-3 {
  top: 3px;
  position: relative;
}

.t-4 {
  top: 4px;
  position: relative;
}

.t-n1 {
  top: -1px !important;
  position: relative;
}

.t-n2 {
  top: -2px !important;
  position: relative;
}

.t-n3 {
  top: -3px !important;
  position: relative;
}

.t-n4 {
  top: -4px !important;
  position: relative;
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1;
  position: relative;
}

.z-index-n1 {
  z-index: -1;
  position: relative;
}

.z-index-5 {
  z-index: 5;
  position: relative;
}

.z-index-10 {
  z-index: 10;
  position: relative;
}

.z-index-8000 {
  z-index: 8000 !important;
}

.z-index-max {
  z-index: 10000 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5;
}

.opacity-75 {
  opacity: .75;
}

.min-height-62 {
  min-height: 62px;
}

.min-height-75 {
  min-height: 75px;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.vh-75 {
  min-height: 75vh !important;
}

.d-none-light {
  display: block !important;
}

.d-none-dark {
  display: none !important;
}

.d-light {
  display: block !important;
}

.d-dark {
  display: none !important;
}

.hidden {
  visibility: hidden;
}

.bg-switch {
  background-color: $bg-switch;
}

.bg-cover {
  background-size: cover;
}

.bg-contain {
  background-size: contain;
}

.bg-center {
  background-position: center
}

.link-before {
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.text-stroke {
  -webkit-text-stroke: 1px $black;
  color: $white;
}

.min-with-150 {
  min-width: 150px;
}

.min-with-200 {
  min-width: 200px;
}

.min-with-220 {
  min-width: 220px;
}

.min-with-16 {
  min-width: 16px;
  text-align: center;
}

.overflow-xl-visible {
  overflow: hidden;
}

.bg-cyan {
  background-color: $cyan;
}

.bg-blue {
  background-color: $blue;
}

.bg-red {
  background-color: $red;
}

.bg-yellow {
  background-color: $yellow;
}

.bg-purple {
  background-color: $purple;
}

.bg-green {
  background-color: $green;
}

.bg-orange {
  background-color: $orange;
}

.bg-pink {
  background-color: $pink;
}

.bg-gray-200 {
  background-color: $gray-200;
}

.bg-gray-300 {
  background-color: $gray-300;
}

.bg-gray-400 {
  background-color: $gray-400;
}

.bg-gray-500 {
  background-color: $gray-500;
}

.bg-gray-600 {
  background-color: $gray-600;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-nowrap {
  white-space: nowrap;
}

.text-spacing {
  letter-spacing: 1.5px;
}

.text-underline {
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}

.line-height-sm {
  line-height: $line-height-sm;
}

.line-height-lg {
  line-height: $line-height-lg;
}

.super-small,
label:not(.form-check-label) {
  font-size: 1rem;
  line-height: 1.4;
}

.hyphens {
  hyphens: auto;
}

.max-height-85 {
  max-height: 85px;
  overflow: hidden;
}

.ratio-3-4 {
  padding-top: 133.33%;
}

.ratio-4-3 {
  padding-top: 75%;
}

.ratio-3-2 {
  padding-top: 66.66%;
}

.ratio-16-9 {
  padding-top: 56.25%;
}

.ratio-21-9 {
  padding-top: 42.8571%;
}

.ratio-1-1 {
  padding-top: 100%;
}

.fill-primary {
  fill: $primary;
}

.img-sm {
  min-width: 100px;
  max-width: 100px;
}

.img-md {
  min-width: 130px;
  max-width: 130px;
}

@mixin clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

@mixin clamp-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

@mixin clamp-4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

@include media-breakpoint-up(sm) {
  .w-sm-auto {
    width: auto !important;
  }

  .img-md {
    min-width: 150px;
    max-width: 150px;
  }

  .img-sm {
    min-width: 110px;
    max-width: 110px;
  }
}

@include media-breakpoint-up(md) {
  .border-md {
    border: 1px solid $border-color;
  }
}

@include media-breakpoint-up(lg) {
  .border-lg-0 {
    border: none !important;
  }

  .border-top-left-radius-0 {
    border-top-left-radius: 0 !important;
  }

  .border-bottom-right-radius-0 {
    border-bottom-right-radius: 0 !important;
  }

  .bg-lg-light {
    background-color: $gray-100;
  }

  .bg-lg-light .btn-share {
    &:hover {
      background-color: $white;
    }
  }

  .bg-lg-white {
    background-color: $white;
  }

  .ratio-lg-21-9 {
    padding-top: 42.8571%;
  }

  .vh-lg-100 {
    min-height: 100vh !important;
  }

  .vh-lg-75 {
    min-height: 75vh !important;
  }
}

@include media-breakpoint-up(xl) {
  .overflow-xl-visible {
    overflow: visible;
  }

  .position-xl-fixed {
    position: fixed;
  }

  .w-xl-100 {
    width: 100% !important;
  }

  .position-xl-absolute {
    position: absolute;
  }

  .position-xl-inherit {
    position: inherit;
  }

  .bg-xl-light {
    background-color: $gray-100;
  }

  .bg-xl-switch {
    background-color: $bg-switch;
  }

  .ratio-xl-4-3 {
    padding-top: 75%;
  }

  .border-top-left-radius-xl-0 {
    border-top-left-radius: 0 !important;
  }

  .container-xl-fluid {
    max-width: none;
  }
}

@include media-breakpoint-up(xxl) {
  .ratio-xxl-flat {
    padding-top: 36%;
  }
}
