@font-face {
  font-family: "Roboto";
  font-weight: 200;
  src: url("../fonts/Roboto-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  src: url("../fonts/Roboto-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-weight: 600;
  src: url("../fonts/Roboto-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  src: url("../fonts/Roboto-Bold.woff2") format("woff2");
}
