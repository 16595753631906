::selection {
  background-color: $primary;
  color: $white;
}

#__bs_notify__ {
  display: none !important;
}

body {
  overflow-x: hidden;
  padding: 0 !important;
}

#page {
  overflow: hidden;
}

a {
  transition: .15s color ease-in-out;
}

.footer {
  a:not(.btn) {
    color: $white;
    font-weight: 500;

    &:hover {
      color: darken($white, 10%);
    }
  }

  a.text-muted {
    &:hover {
      color: $primary !important;
    }
  }
}

figure {
  margin: 0;

  figcaption {
    font-size: $font-size-sm;
    color: $text-muted;
    margin-top: .5rem;
  }

  picture {
    img {
      background-image: url("../images/svg/loader.svg");
      background-repeat: no-repeat;
      background-size: 65px;
      background-position: center;
    }
  }
}

.figure {
  display: block;
}

.icon {
  width: 28px;
  fill: $primary;
}

.icon-brand {
  width: 32px;
}

.fill-white {
  fill: white;
}

.bg-tetra {
  background-color: #FFED00;
}

.bg-euk {
  background-color: #171B51;
}

.bg-iams {
  background-color: #F39100;
}

.bg-fur {
  background-color: #92D500;
}

.bg-miracle {
  background-color: #BA0A2F;
}

.bg-bones {
  background-color: #791214;
}

.bg-8in1 {
  background-color: #E3271C;
}

.bg-meowee {
  background-color: #F39600;
}

.bg-goodboy {
  background-color: #FEEACD;
  color: #F18A13;
}

.logo {
  max-width: 220px;
  max-height: 100px;
}

.img-product {
  img {
    max-width: 580px;
    max-height: 720px;
  }
}

.img-product-sm {
  img {
    transform: scale(.8);
  }
}

.frame {
  margin-top: -10rem;
  height: 130%;
  margin-left: -5rem;
  margin-right: -5rem;
}

.height {
  min-height: 300px;
}

@include media-breakpoint-up(xl) {
  .height {
    min-height: 700px;
  }
}

@include media-breakpoint-down(xl) {
  .frame {
    min-height: 800px;
    margin-top: -10rem;
    height: 100%;
    margin-left: -5rem;
    margin-right: -10rem;
  }
}

@include media-breakpoint-down(md) {
  .frame {
    min-height: 600px;
    margin-top: -6rem;
    margin-left: -3rem;
    margin-right: -5rem;
  }

  .img-product {
    img {
      max-width: 500px;
      max-height: 560px;
    }
  }
}


@include media-breakpoint-down(sm) {
  .frame {
    min-height: 460px;
    margin-top: -6rem;
    margin-left: -2rem;
    margin-right: -3rem;
  }

  .img-product {
    img {
      max-width: 320px;
      max-height: 360px;
    }
  }
}
